import './Styles/Hireus.css'


export default function HireUs(props){


return (<>
<a href={props.link ?? props.link} target='_blank' >
<button onClick={props.click?? props.click} className="  transition ease-in-out delay-50
 hover:bg-black hireus  hover:-translate-y-1 hover:scale-110 hover:bg-customBlue duration-300 ...">

 { !props.text ?
(<a href='/hire-us'> Get In Touch
</a>):props.text}
    </button> 
    </a> 
</>)
}