import { Link } from "react-router-dom";
import { blackLogo } from "../../constants/imports";
import { FiPhoneCall } from "react-icons/fi";
import { VscMailRead } from "react-icons/vsc";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { footerSocialLinkData, menuItems } from "../../constants/data";

const Footer = () => {
  const genericLink = "flex font-light text-xs hover:text-primary my-1 p-1";
  const colHeader = "font-black text-md uppercase mb-2";
  const Infoemail = "info@rwdynamics.com";
  const InfomailtoLink = `mailto:${Infoemail}`;
  return (
    <div className="res-cont bg-faintBlue text-customBlue justify-between white">
      {/* max-w-[1240px] mx-auto grid grid-cols-1 md:grid-cols-3 */} 
      <div className=" md:flex items-start justify-between border-b border-customBlue md:pt-4 md:pb-4 pb-4 max-w-[1240px] mx-auto grid grid-cols-1 md:grid-cols-3">
        {/* Firtst Column  */}
        <div className="">
          <img src={blackLogo} className="max-w-[200px]" alt="ABout Us" />
          <p className="md:text-xs text-sm text-justify max-w-[200px]">
            we are dedicated and commited to cultivating exceptional products
            and relationships, driving business growth through the application
            of technology.
          </p>
        </div>
        {/* 2nd and Third COl */}
        <div className="block md:flex items-start justify-center md:space-x-20">
          {/* Second Column */}
          <div className="flex items-start justify-center space-x-12 md:py-0 py-8">
            {/* Links */}
            <ul className="">
              <h1 className={colHeader}>links</h1>
              <li style={{ cursor: "pointer" }} className={genericLink}>
                <Link to={"/"}>Home</Link>
              </li>
              {menuItems.map((item, index) => (
                <li
                  key={index}
                  style={{ cursor: "pointer" }}
                  className={genericLink}
                >
                  <Link to={item.path}>{item.title==='Why RWN Dynamics'?'Why RemotewebNET Dynamics':item.title}</Link>
                </li>
              ))}
            </ul>
            {/* Socail MEdia */}
            <ul className="">
              <h1 className={colHeader}>Socials</h1>
              {footerSocialLinkData.map((item, index) => (
                <li
                  key={index}
                  style={{ cursor: "pointer" }}
                  className={genericLink}
                >
                  <a href={item.path} target="_blank">
                    {item.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          {/* Third COl */}
          <div className="flex items-center justify-center">
            <div>
              <h1 className={colHeader}>Contact</h1>
              {/* Call Us */}
              <div className="flex items-center">
                <FiPhoneCall size={25} className="text-customBlue" />
                <p className="text-xs ml-2">+2349066005044</p>
              </div>
              {/* Email Us */}
              <div className="flex items-center my-4">
                <VscMailRead size={25} className="text-customBlue" />
                <p className="text-xs ml-2">
                  {" "}
                  <a href={InfomailtoLink} target="_blank">
                    info@rwndynamics.com
                  </a>
                </p>
              </div>
              <div className="flex items-center">
                <HiOutlineLocationMarker
                  size={25}
                  className="text-customBlue"
                />
                <p className="text-xs ml-2">
                Zambezi Crescent, 1st floor Murjanatu House, <br /> Maitama, FCT Abuja
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h1 className="text-2xl bold my-2">Locate Us</h1>

      <iframe  className ="mb-8 rounded-xl " src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d44574.01564585022!2d7.485356991109583!3d9.079830120667266!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104e0becd03f9b03%3A0xdcda76640b27906e!2sMurjanatu%20Building!5e0!3m2!1sen!2sng!4v1720441095820!5m2!1sen!2sng"
         width="100%" height="450" 
         style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      <p className="py-4 font-light text-center">
        © 2024. RemotewebNET Dynamics. All Rights Reserved.
      </p>
    </div>
  );
};

export default Footer;
