
import './Styles/style.css'
import { Journeys } from '../../constants/data'
import JourneyItem from './Journeyitem'
import { Beleive,WhyMatter } from '../../constants/imports'
export default function Impact (){


    return(<>
    <div className="md:p-10 grid  md:grid-cols-2	">

        <div className=" flex justify-center align-center flex-column p-5 ">
        <h2 className=" text-customBlue">Impact</h2>
        <p className='p'>
        At RemotewebNET Dynamics, we take immense pride in our commitment to shaping the future of young talent. Our mission goes beyond merely providing a platform; we are dedicated to transforming lives, one skill set at a time. The heartbeat of our organization lies in our unwavering focus on empowering the youths, and our Impact page is a testament to the stories of growth and success that have unfolded through our endeavors.
        </p>
        </div>
       
        <div className='p-5'>
<h2>Our Commitment to Youths:</h2>
<p className='p' >At the core of our values is the belief that talent knows no bounds, and it is our duty to unearth, nurture, and empower young minds. We have taken it upon ourselves not just to offer a platform, but to handpick talented individuals and equip them with the tools they need to thrive. Our commitment extends beyond the digital realm. We provide not only laptops and access to our platform of intense training but also mentorship that guides them through the challenging journey of skill development.
</p>
        </div>
    </div >

    <div className='md:p-[50px] bg-[#F4F6FF] flex justify-center flex-column align-center items-center' >

        <h2 className=' flex align-center justify-center '>The Journey of Transformation:</h2>
       
       {Journeys.map((data)=>(<JourneyItem img={data.img} text={data.text} /> ))}
        </div>

   <div className='md:p-10 grid  md:grid-cols-2'>

   <div className=" flex justify-center align-center flex-column p-5">
        <h2 className=" text-customBlue">The Ripple Effect</h2>
        <p className='p '>

        What sets RemotewebNET Dynamics apart is our commitment to creating a ripple effect of positive change. As we empower individuals like Ibrahim, Dan, Eyo and so many others, they, in turn, contribute to the growth of our organization and the community at large. The impact isn't confined to the individual; it echoes through the fabric of society, creating a more skilled, empowered, and resilient generation of the African youth.        </p>
        </div>

        
        <div className=" p-5">
       <img className='w-[100%]' src={Beleive}/>
        </div>

        <div className=" p-5">
       <img className='w-[100%]' src={WhyMatter}/>
        </div>
        <div className=" flex justify-center align-center flex-column p-5">
        <h2 className=" text-customBlue">Why It Matters:</h2>
        <p className='p '>
 
        Our dedication to impacting skill sets is rooted in the belief that empowered individuals drive innovation, economic growth, and societal progress. By investing in these youths, we contribute to a brighter future for the nation. It's not just about the skills; it's about creating a pipeline of confident, capable individuals who can stand on their own and, in turn, uplift others.
Join us on this journey of transformation. Together, let's shape a future where talent knows no
limits, and every young mind has the opportunity to thrive.            </p>
        </div>

   </div>
    </>)
}