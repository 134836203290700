import Hero from "./HeroContainer";
import { HeroServices, menuItems } from "../../constants/data";

export default function(props){


    return (<>
    <Hero slug ={props.slug}/>
    <div className="grid md:grid-cols-2 p-[50px]">

        <div className="md:mr-[100px]"> <h2>Key Features:</h2><ul>
           { HeroServices.map((item)=> { 
            if(item.slug===props.slug){


            return(item.features)


           }  })}
            </ul></div>
        <div className="md:ml-[100px]">
            <h2>Benefits:</h2>

            <ul>
            { HeroServices.map((item)=> { 
            if(item.slug===props.slug){


            return(item.benefits)


           }  })}
            </ul>
        </div>
    </div>
    
    </>)
}