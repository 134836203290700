import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function ErrorModal(props) {
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      

      <Modal show={show} onHide={handleClose}>
        <div className='p-6'  >
          <h2 className=' text-[20px] font-thin'>{props.title}</h2>
          <hr/>
     
        <div><p className='font-thin py-4 ' >{props.msg}</p></div>
         
          <div className ='w-full flex justify-center items-center'><button  className='px-2 py-1 font-thin bg-black text-white rounded active:bg-white active:text-black'  onClick={()=>props.handleClose(false)}>
            Close
          </button></div>

          </div>
          
        
      </Modal>
    </>
  );
}

export{ErrorModal}