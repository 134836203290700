import { differntiators } from '../../constants/data'
import DifferntiatorItem from './DifferntiatorItem'
import './Styles/style.css'

export default function WhyUs(){


    return(<><div className='px-10 md:px-40 py-8 md:py-20'>


        <h2>
        Why RemotewebNET Dynamics?
        </h2>
        <p className='w-[90%] p'>At RemotewebNET Dynamics Ltd., our story is one of relentless innovation, client-centric brilliance, and an unwavering commitment to shaping the digital future. Since our inception in 2012, we've embarked on a transformative odyssey, charting a course through the realms of technology and consistently raising the bar in the IT industry.</p>
    </div>

    <div className='bg-[#090E2F] md:px-40 md:py-20 flex justify-center flex-column align-center items-center '>
 
    <h2 className=' text-white '>
    Key Differentiators
            </h2>

            {differntiators.map((item)=>(<DifferntiatorItem details={item.details} title={item.title} img ={item.img}/>))}


        </div>
        </>)
}