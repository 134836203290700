import { whatwedo_data } from "../../constants/data";
import WWDitem from "./WWDitem";



export default function () {

    return (<div className=" p-10  bg-[#F4F6FF] flex justify-center items-center flex-column ">
        <h1 className="heading m-4">What We Do</h1>


        <div className="grid grid:cols-1 md:grid-cols-4 ">

            {whatwedo_data.map((item, key) => 
            
            { 
                return   (
              <WWDitem item={item} keyy={key} />
           
              )
            }
              )}

        </div>

    </div>)
}