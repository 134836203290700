import { FaExternalLinkAlt } from "react-icons/fa"
import Hero from "./Hero"
import Button from "../HireUs/HireUsButton";
import { productData } from "../../constants/data";
import { Justeviaimg, Justeviaimg1 } from "../../constants/imports";

const Partners =()=>{

  

    const partnerData=[
      
      
      {title:'Justevia', desc:"Justevia stands as a beacon of health and vitality, offering a delightful range of artisanal tea blends\
    that redefine the concept of wellness. Driven by a shared vision of holistic wellness, Justevia's\
    emphasis on sustainability and community engagement resonates deeply with our values.\
    As partners, we are united in our mission to promote health, nurture sustainability, and inspire\
    positive change. Together, we're cultivating a culture of wellness one step at a time. We're proud to\
    partner with Justevia in our shared mission to promote health, mindfulness, and sustainability.\
    Together, we're shaping a brighter, healthier future.", img:Justeviaimg1, url:'https://www.justeviateas.com'},


      {title:'RemoteWebNet Dynamics in Partnership with Justevia      ', desc:"Empowering Women in Rural Communities: Sustainable Agriculture and Technology Innovation for Youth Enterprise Growth\
\
      RemoteWebNet Dynamics is thrilled to announce our partnership with Justevia to bring a positive change to women in the Gasaki community.\
      \
      This collaboration aims to empower women through teaching  them sustainable farming practices, providing them with needed resources, and educating them on how to be resourceful enhancing their overall well-being.\
      \
      One of our contributions to this project as a partner is to develop a specialized data collection app to efficiently gather and analyze data related to the project.\
       We are happy to be part of the GASAKI Project",
        img:Justeviaimg, url:'https://www.justeviateas.com'}

    
  
  
  ]



    return(<div className="flex- flex-col justify-center items-center">
<Hero/>
<div className=" self-center content-center grid grid-cols-2 p-10 items-center place-items-center">
{partnerData.map((item, index) => (
          <div key={index} className="mt-4 md:w-[500px] shadow-sm rounded">
            <img   src={item.img} alt="Product" className=" image  border border-primary w-[100%]" />
            <h1 className=" ml-4 text-lg font-semibold text-customBlue py-3">
              {item.title}
            </h1>
            <p className="ml-4 text-customBlue text-sm">{item.desc}</p>
            <div className="p-3 w-[100%] flex justify-center items-center">

<Button link={item.url}  text={(<><FaExternalLinkAlt /></>)} />
</div>
          
          </div>
        ))}

</div>
    </div>)
}

export{Partners}