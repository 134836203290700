
import { Blog } from "../component/Blog/Blog"
import { Header, Footer } from "../component/Layout"
 export default function () {


    return (<div>
        <Header />
        <Blog/>
        <Footer />
    </div>)
}