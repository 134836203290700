import { useEffect, useState } from "react"
import { MdCancel } from "react-icons/md";
import './Styles/style.css'
import { LuRefreshCw } from "react-icons/lu";
import { cloud_services, hardware_solutions, ict_installations, it_support, software_solutions } from "./Constants";
import { blackLogo } from '../../constants/imports'
import { IoSend, IoAttach } from "react-icons/io5"
function Response(data) {

    return <div id="responss" className={data.type}>
        {data.m.map((e, key) => {
            var loading = data.l && key == 3 ? 'animate-pulse' : ''
            return (e.content != '' ? <p className={e.role == 'bot' ? `botm ${loading}` : 'userm'} key={key}>{e.content}</p> : '')
        })}
    </div>
}

export default function Chatbotview(props) {



    async function get_message(msg, setres, setloading) {
        console.log('value', msg)

        const apiUrl = 'https://rwncahtbotapi.onrender.com/message';

        const requestBody = [
            {
                "role": "system",
                "content": "You are a helpful assistant.  \
            I want you to use this text after the pound symbol as context to answer some questions always respond in a first-person point of view. use We instead of their, use US instead of they,  \
             only available within the text. Anything or question asked outside the text provided \
             \should be responded with 'We are RemotewebNET Dynamics, a leading provider of IT consulting, Technical support, and IT Solutions for medium and large-sized businesses. How may we help you?' but first search thoroughly in the context and try your best to answer from the text provided, be flexible in using the context here is the text for context #     We are RemotewebNET Dynamics, a leading provider of IT consulting, Technical support, and IT Solutions for medium and large-sized businesses. It’s a privately owned company founded in the year 2012 with its headquarters located in Abuja Nigeria. Our experienced technicians and engineers work with clients to understand their unique technology needs and provide solutions.\
             We deliver exceptionally efficient and secure software applications with a seamless user experience:\
             \
             Our services include: \
             \
             1. UI/UX & Product Design\
             (we specialize in elevating digital experiences through cutting-edge Design solutions.)\
             \
             2. Mobile App Development\
             (excel in crafting innovative and functional mobile applications that bring your ideas to life.)\
             \
             3. Cloud Architecture\
             (Empower your business with scalable solutions through our Cloud Architecture services)\
             \
             4. Web Development\
             (we are your trusted partner for robust and dynamic web development solutions.)\
             \
             Our contact details:\
             \
             Call Us on\
             +2348033917294\
             \
             Online Support\
             Support Service is always\
             available for you 24/7\
             \
             Mail Us\
             info@rwndynamics.com\
             \
             \
             Working Hours\
             Monday to Friday\
             \
             8:00am-5:00pm\
             \
             Our physical location is  Suite A13 Saham Plaza,\
             Wuse 2 FCT Abuja\
             "


            },
            {
                "role": "system",
                "content": "What is this company all about?"
            },
            {
                "role": "system",
                "content": msg
            }
        ];

        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        })
            .then(response => response.json())
            .then(data => {
                console.log('Response from server:', data.message.content);
                setres(data.message.content)
                setloading(false)
                return data.message.content;

            })
            .catch(error => {
                setres("Opps, an error ocoured, check your internet connection")
                console.error('Error:', error);
            });

    }


   
    var data = [{ li: 'Contact Support' }, {
        li:
  'Software Solutions'
    },
    { li: 'Hardware Solutions' },
    { li: 'Cloud Services' },
    { li: 'ICT Installation Services' },
    { li: 'IT Support' }]
    var default_message = (<div className=""><p>Please tell us what you are looking for:</p><ul>

        {data.map((value, key) => (<li onClick={()=>handlechoice(key)} className=" rounded-lg text-[13px] mt-2 active:bg-customBlue active:text-white text-[#666666] font-bold flex justify-center p-1 bg-[#fefefe]">{value.li}</li>))}
    </ul>
    </div>)
    var [botmsg, setbotmsg] = useState("")
    var [inputvvv, setinput] = useState('')
    var [userin, setusi] = useState('')
    var [inv, setinv] = useState('')
    var [isloading, setisloading] = useState(false)
    var temp = [{ role: 'bot', content: 'Hello and welcome, thank you for getting in touch.' }, { role: 'bot', content: default_message }, { role: 'user', content: userin }, { role: 'bot', content: botmsg }]
    var [messages, setmss] = useState(temp)
    useEffect(() => {

        setmss(temp)
if(!userin==''){
scrolldown()
setTimeout(() => {
    scrolldown()
}, 200);

}
      

    }, [botmsg, userin])




    var contact_support_form=(<div><p>

        
    </p>
    <form>
   <label>Email</label>
   <input placeholder="Enter your email"/>
   <label>Message</label>
   <input placeholder="Enter your message"/>
    
   </form>
    </div>)


    function handlechoice(key){

        if(key==0) 
        {setbotmsg(<>Thank you for reaching out, please call +2349066005044 or send us an email on info@rwndynamics.com</>)
    setusi(data[key].li)
    }
    if(key==1){
        setbotmsg(software_solutions)
        setusi(data[key].li)
    }
    if(key==2){
        setbotmsg(hardware_solutions)
        setusi(data[key].li)
    }

    if(key==3){
        setbotmsg(cloud_services)
        setusi(data[key].li)
    }
    if(key==4){
        setbotmsg(ict_installations)
        setusi(data[key].li)
    }
    if(key==5){
        setbotmsg(it_support)
        setusi(data[key].li)
    }
     }
    function scrolldown() { 

        var element = document.getElementById('scroll-conatiner');
    
       element.scrollTop = element.scrollHeight;
     }
    function scrollup() {

        var element = document.getElementById('scroll-conatiner');
        if (element) {
            element.scrollTop = 0;
        }

    }
    async function sendmsg() {
        scrolldown()

        setinv('')
        var rg = await get_message(inputvvv, (e)=>{
 
          setbotmsg(e)
          scrolldown() 
        }, setisloading)
        setbotmsg('...')
        setisloading(true)


    }
    return <div className='chatbot-view transition-all ease-in-out delay-1000  '>
        <div className="bot-header">

            <LuRefreshCw onClick={() => {
                scrollup()
                setbotmsg('')
                setusi('')
                setinv('')
                setinput('')

            }} size={'1.5em'} className="rounded-lg active:bg-[grey]" />

            <img width={100} src={blackLogo} />
            <MdCancel size={'1.5em'} className="rounded-lg active:bg-[grey]" onClick={props.cancel} class="fa-solid fa-xmark" />
        </div>
        <div className="main-chat">
            <div id='scroll-conatiner' className="msgs">

                <Response l={isloading} m={messages} />
            </div>


            <div className="type-msg">
            <input id='filepicker' className="hidden" type='file'/>
                <IoAttach onClick={()=>{
var filepick=document.getElementById('filepicker')
filepick.click()

                }} className="rounded-lg active:bg-[grey]" color='#293586' size={'1.5em'} />
                <input value={inv} onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        sendmsg()
                    }
                }}
                    onChange={(e) => {
                        setisloading(true)
                        scrolldown()
                        setinv(e.target.value)
                        setinput(e.target.value)
                        setusi(e.target.value)



                    }} placeholder="Type your message here..." type="text" id='input' className="type-in" />
                <IoSend className="rounded-lg active:bg-[grey]" color='#293586' size={'1.5em'}
                    onClick={
 
                        sendmsg
 
                    } class="fa-solid fa-paper-plane" />
            </div>

        </div>
    </div>
}