export default function(props){


    return(<div className=" m-8 grid md:grid-cols-2 flex justify-around align-around ">
    <img src={props.img} />
    <div className="md:m-0  mt-2">
        <h3>{props.title}</h3>
        <p className="text-white">{props.details}</p>
    </div>
    </div>)
}