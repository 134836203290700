import React from "react";

const ServiceItem = ({ item }) => {
  return (
    <div className="bg-white px-8  hover:bg-customBlue py-10 rounded-md drop-shadow-md   md:mx-3  my-4">
      {/* Img */}
      <div className="bg-pink-100 hover:bg-customBlue w-[100px] flex items-center justify-center py-4 rounded-md mb-6">
        <img className='image' src={item.img} alt="services" />
      </div>
      {/* Title */}
      <h1 className="text-sm font-bold text-customBlue mb-3">{item.title}</h1>
      {/* Desc */}
      <p className="text-xs text-customBlue">{item.desc}</p>
    </div>
  );
};

export default ServiceItem;
