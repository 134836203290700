import React from "react";
import {
  About,
  Client,
  ContactUs,
  Hero,
  MoreServices,
  Portfolio,
  Services,
} from "../component/Home";
import { Footer } from "../component/Layout";
const Home = () => {
  return (
    <div>
      <Hero />
    
      <About />
      <Services />
      <Client />
      <MoreServices />
      <Portfolio />
      <ContactUs />
      <Footer />
    </div>
  );
};

export default Home;
