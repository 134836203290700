import React from "react";
import { clientData } from "../../constants/data";

const Client = () => {
  return (
    <div className="pb-12 relative hidden md:block">
      {/* Header */}
      <div className="bg-white py-6">
        <h1 className="text-[#484848] text-2xl font-bold ml-4 text-center ">Clients</h1>
      </div>
      {/* Photos */}
      <div className="md:px-28 px-12 h-[80px] bg-white w-full">
        <div className="w-full flex justify-between items-center bg-white border-[1px] border-gray-300 px-12 py-8">
          {clientData.map((item, index) => (
            <img src={item} key={index} alt="Client" />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Client;
