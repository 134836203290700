import React from "react";
import Header from "../Layout/Header";
import { Link } from "react-router-dom";
import { tech } from "../../constants/imports";

const Hero = () => {
  return (
    <div className="hero w-full h-[85vh]">
      <Header />
      <div className="p-4 h-full w-full flex justify-center items-center">
        <div className="flex flex-col justify-center items-center w-full md:w-[70%]">
          <h1 className=" inline text-white text-lg md:text-4xl font-bold text-center md:px-6">
            We leverage <img className="animate-pulse h-8  inline md:h-[70px]" src={tech} /> to
            simplify and Improve the way you work
          </h1>
          <p className="text-lg font-thin text-white py-6 text-center md:px-28 px-8">
            We build and manage software products across fintech, data analytics, AI, E-Commerce while offering top-notch IT support and Mentorship
          </p>
          {/* <Link to={"/hire-us"} className="primary-btn">
            Hire US
          </Link> */}
        </div>
      </div>
    </div>
  );
};

export default Hero;
