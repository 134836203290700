import React from "react";
import { useParams } from "react-router-dom";
import { ServicesContainer } from "../component/Services";
import { Footer, Header } from "../component/Layout";

const Services = () => {
  const params = useParams();
  const { slug } = params;
  console.log(slug);
  return (
    <div>
      <Header />
      <ServicesContainer slug={slug} />
      <Footer />
    </div>
  );
};

export default Services;
