
import { Header, Footer } from "../component/Layout"
import WhyUs from '../component/WhyUs/WhyUs'
export default function () {


    return (<div>
        <Header />
        <WhyUs/>
        <Footer />
    </div>)
}