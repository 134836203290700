


var data = {
    projectType: '',
    services: [],
    document: 'have_doc file',
    fullname: '',
    email: '',
    phone: '',
    project_desc: '',
    country: 'Nigeria',
    remark: '',
    how_youfoundus: ''
}


const GIT_reducer= (state = { details: data , loading:false}, action)=> {
    switch (action.type) {
case 'tg-loading':
    return {... state,loading:action.payload}

        case 'project_type':
            return { ...state, details: { ...state.details, projectType: action.payload } }

        case 'services':
            var interested_services =[... new Set ([... state.details.services, ... action.payload])]
            return { ...state, details: { ...state.details, services: interested_services } }

        case 'document':

            return state

        case 'name':
            return { ...state, details: { ...state.details, fullname: action.payload } }


        case 'email':
            return { ...state, details: { ...state.details, email: action.payload } }

        case 'phone':
            return { ...state, details: { ...state.details, phone: action.payload } }



        case 'project_desc':
            return { ...state, details: { ...state.details, project_desc: action.payload } }




        case 'country':
            return { ...state, details: { ...state.details, country: action.payload } }

        case 'remark':
            return { ...state, details: { ...state.details, remark: action.payload } }


        case 'how_youfoundus':
            return { ...state, details: { ...state.details, how_youfoundus: action.payload } }
case 'test':
    return state

default :
return state

    }



}

export {GIT_reducer}