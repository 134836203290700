import React from "react"
import './Styles/style.css'
export default function Chatbotbtn(props){
return <div onClick={props.click} className='chatbtn-container animate-bounce'>

<i class=" font-[22px] text-lg transition ease-in-out delay-150 fa-solid fa-comment-dots"></i>


 
</div>
}