import React, { useEffect } from "react";
import { Footer, Header } from "../component/Layout";
import { CoreValues, MissionVision, Hero } from "../component/About";
 import Team from "../component/About/Team";

import { HashRouter as Router, Route, NavLink ,useLocation } from 'react-router-dom';
import MiniHero from "../component/About/MiniHero";
import Whatwedo from "../component/About/Whatwedo";
import { chooseus } from "../constants/imports";
import Loader from "../component/Loader";

const About = () => {
  const location = useLocation();


  useEffect(() => {
    // Scroll to the top when a new route is opened
    window.scrollTo(0, 0);

    // Scroll to a specific section based on the route
    const sectionId = location.hash.substring(1);
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }

   }, [location.hash]);

  return (<>


  
 

    <div>


      <Header />
      <Hero/>
      <div id='mission'>
      <MissionVision />

      </div>

      <MiniHero />

    
    
     <div id="whatwedo">
     <Whatwedo/>
    

     </div>
<div id="core">
<CoreValues/>
</div>
   
<div id='team'>

<Team />

</div>
<div className="res-cont md:px-10 grid grid-cols-1 md:grid-cols-2 justify-start items-center">
    
     {/* Image */}
     <div className=" md:mx-10 flex md:justify-end justify-center">
        <img src={chooseus} alt="ABout Us" className="w-[400px]" />
      </div>


    
     {/* Description */}
      <div className=" mx-4 md:mx-10 my-2 py-5 md:pr-10 w-full">
        
        <h1 style={{}} className="heading">
        Choose Empowerment with RemotewebNET Dynamics Ltd.
        </h1>
        <p className="font-light text-customBlue text-sm mt-8">
 
        Whether you're an individual seeking personal growth or a business aiming for digital excellence, RemotewebNET Dynamics Ltd. is here to empower and mentor you. Let's embark on a journey where technology becomes a force for positive change.
Using a step-by-step approach ensures that attention is given to each aspect of the project for a successful outcome. To maximize benefits to our clients, staff, shareholders, and the communities we operate in. RemotewebNET Dynamics aligns itself with the guiding principles of Team work, communication, and Transparency. We value fidelity, cooperation, and honesty.
        </p>


      </div>
     
    </div>




    <div className=" bg-[url('./assets/aboutbg002.png')]  bg-cover res-cont md:px-10 grid grid-cols-1 md:grid-cols-2 justify-start items-center">
    
  
  
   
    {/* Description */}
     <div className=" mx-4 md:mx-10 my-2 py-5 md:pr-10 w-full">
       
       <h1 className="heading text-white">
       Experience empowerment through       </h1>
       
       <h1 className="heading m-2 text-white md:text-[50px]">
       technology       </h1>

       <h1 className="heading text-white">
       with RemotewebNET Dynamics Ltd.     </h1>
     </div>


       {/* Image */}
    <div className=" mx-10 flex md:justify-end justify-center">
       <img src={chooseus} alt="ABout Us" className="w-[400px]" />
     </div>

    
   </div>

      <Footer />
    </div>
    </>
  );
};

export default About;
