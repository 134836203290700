
import './Styles/style.css'
import { jbgimg } from '../../constants/imports'

export default function JourneyItem(props){



    
    return(<>
    <div className="   grid md:grid-cols-2 md:p-10 flex m-10 justify-center items-center	">

        <div className='flex  mr-4 justify-center  align-center flex-column items-center'>
            <p className='p' >{props.text}</p>
            </div> 
            <div>
                <img  className={`img`} src={props.img}/>
            </div>
    </div>
{/* nnn */}
    </>)   
}