
import './Styles/style.css'
const hardware_solutions=()=>(<> Hardware Solutions
<p>▪ Robust Servers</p>
Ensure the foundation of your digital operations is solid with our range of robust servers.
Whether you're a small business or an enterprise, our servers are engineered to deliver
exceptional performance, reliability, and scalability. Experience seamless data processing
and storage capabilities that lay the groundwork for a resilient IT infrastructure.
<p>▪ Leading-edge Workstations</p>
Empower your workforce with workstations that enhance productivity and efficiency. Our
hardware solutions include high-performance workstations tailored to meet the demands
of modern business applications, enabling your teams to work smarter and achieve
optimal results.
<p>▪ Networking Equipment</p>
Build a robust and secure network with our advanced networking equipment. From
routers and switches to firewalls, our hardware solutions provide the backbone for
seamless communication and data transfer within your organization. Ensure a stable and
secure network environment to support your day-to-day operations.<a href='#'>Learn more here</a> </>)

const cloud_services=()=>(<><p> Hosted Email Services: Elevate Communication</p>
    In the digital age, effective communication is the cornerstone of successful business operations.
    RemotewebNET Dynamics Limited is proud to offer state-of-the-art Hosted Email Services,
    providing a secure, reliable, and feature-rich platform for businesses to enhance their
    communication infrastructure.
    
   <p> Domain Registration Services: Claim Your Digital Identity</p>
In the vast landscape of the internet, your domain is not just a web address; it's the digital face
of your business. RemotewebNET Dynamics Limited is pleased to offer Domain Registration
Services, a fundamental step in establishing and securing your online presence.

<p> Backup and Disaster Recovery: Safeguarding Your Future</p>
In the unpredictable landscape of today's digital world, ensuring the safety and continuity of your
critical data is paramount. RemotewebNET Dynamics Limited proudly presents our Backup and
Disaster Recovery Services, a robust solution designed to protect your business against data loss,
disruptions, and unforeseen disasters.

<p>Virtual Desktop Solutions: Transform Your Work Environment</p>
In the era of remote work and dynamic business landscapes, having a flexible and secure
computing environment is crucial. RemotewebNET Dynamics Limited introduces our Virtual
Desktop Solutions, a transformative approach to computing that enhances collaboration,
flexibility, and efficiency within your organization.
<a href='#'>Learn more here</a>
</>)
const ict_installations=()=>(<>
<p>▪ Efficient Installations</p>
When it comes to IT installations, precision is key. Our experienced team at
RemotewebNET excels in setting up hardware, deploying software solutions, and
configuring networks with meticulous attention to detail. From the initial planning stages
to the final rollout, we ensure that your installations are executed efficiently, minimizing
disruptions, and maximizing the effectiveness of your technology investment.
 
<p> ▪ Network Configuration and Optimization</p>
A robust and well-configured network is the backbone of any IT infrastructure. Our experts
at RemotewebNET take pride in configuring and optimizing networks to meet the specific
requirements of your business. Whether it's ensuring secure and reliable connectivity or
implementing advanced features for improved performance, we tailor our services to
align with your network goals.

<p>▪ Project Management Excellence</p>
Embarking on IT projects requires careful planning, coordination, and execution. Our
project management expertise ensures that your IT initiatives are delivered on time,
within budget, and with the expected outcomes. From system upgrades to cloud
migrations, we handle the intricacies of project management, allowing you to focus on
the strategic aspects of your business.
<p>▪ Technology Upgrades and Migrations</p>
Staying current with technology is essential for business competitiveness. Our team is
well-versed in orchestrating seamless technology upgrades and migrations, ensuring that
your systems evolve with the latest advancements. We mitigate risks, optimize
performance, and guide you through the process to make transitions as smooth as
possible.
<p>▪ Custom Solutions for Unique Needs</p>
Recognizing that each business is unique, we approach installations and projects with a
commitment to understanding your specific needs. Our solutions are tailored to your
industry, size, and growth trajectory, ensuring that the technology we implement aligns
seamlessly with your organizational objectives.
<p>▪ Post-Implementation Support</p>
Our dedication extends beyond the initial implementation phase. We provide robust postimplementation support to address any issues that may arise and ensure that your
systems continue to operate optimally. This commitment to ongoing support guarantees
the longevity and effectiveness of the solutions we implement. <a href='#'>Learn more here</a> </>)


const software_solutions=()=>(<>Software Solutions:
<p>▪ Tailored Software Applications</p>
Enhance your business processes with our tailored software applications. Whether you
need customized solutions or industry-standard software packages, our offerings are
designed to streamline operations, improve efficiency, and drive innovation. Stay ahead
of the curve with software that adapts to your unique business requirements.

<p>▪ Security Software</p>
Protect your digital assets with our advanced security software. Safeguard your data,
networks, and applications from cyber threats with our comprehensive security solutions.
We prioritize the integrity and confidentiality of your information, ensuring that your
business remains resilient in the face of evolving cybersecurity challenges.
<p>▪ Productivity Suites</p>
Optimize collaboration and productivity with our suite of business applications. From
office productivity tools to collaboration platforms, our software solutions empower your
teams to work cohesively, whether they're in the office or working remotely. Drive
efficiency and collaboration with technology that adapts to your business workflow. <a href='#'>Learn more here</a></>)

const it_support=()=>(<>RemotewebNET Dynamics Limited offers comprehensive IT support services, ensuring swift and
   responsive issue resolution, proactive monitoring, and user training to empower seamless
   operations. With a commitment to 24/7 support, tailored packages, and a strategic approach, we
   stand as a dedicated partner in maximizing the reliability and efficiency of your IT infrastructure.
   
   <p>Responsive Issue Resolution</p>
At RemotewebNET, we pride ourselves on our swift and responsive approach to issue
resolution. Our expert IT support team is on standby to address and resolve any technical
challenges your organization may encounter promptly. Whether it's software glitches,
hardware malfunctions, or connectivity issues, we aim to minimize downtime and keep
your operations running smoothly.

<p> ▪ Proactive Monitoring and Management</p>
Beyond reactive solutions, our IT support services adopt a proactive stance through
continuous monitoring and management of your IT systems. We employ advanced tools
to track the health of your infrastructure, identify potential issues before they escalate,
and implement preventive measures. This proactive approach contributes to enhanced
system reliability and reduced disruptions.
<p>▪ User Assistance and Training</p>
Empowering your team to navigate and utilize your IT resources effectively is integral to
our support philosophy. In addition to issue resolution, we provide user assistance and
training to enhance digital literacy within your organization. This proactive education
minimizes recurring issues, boosts productivity, and ensures that your workforce
maximizes the benefits of your technology investments.
<p> ▪ Software Updates and Patch Management</p>
Staying current with software updates and security patches is paramount for safeguarding
your digital assets. Our IT support services include meticulous management of software
updates, ensuring that your systems are fortified against vulnerabilities. This commitment
to software hygiene enhances the security and performance of your IT environment.
<p>▪ Hardware Maintenance</p>
The longevity and efficiency of your hardware are critical to a seamless operational
experience. Our IT support extends to comprehensive hardware maintenance, covering
regular health checks, component upgrades, and timely replacements. By proactively
addressing hardware issues, we minimize the risk of failures and extend the lifespan of
your equipment. <a href='#'>Learn more here</a>
</>)

export {
 it_support, ict_installations, cloud_services, software_solutions,hardware_solutions
}