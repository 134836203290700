import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { About, Home, Products, Services } from "./pages";
import { useState } from "react";
import Chatbotbtn from "./component/Chatbot/Chatbotbtn";
import Chatbotview from "./component/Chatbot/Chatbotview";
import HireUs from "./pages/HireUs";
import Impact from "./pages/Impact";
import Whyus from "./pages/Whyus";
import Blog from "./pages/Blog";
import Loader from "./component/Loader";
import { useSelector } from "react-redux";
import Partners from "./pages/Partners";
import { FaWhatsapp } from "react-icons/fa6";

const App = () => {
  var isloading = useSelector((state) => state.loading);

  var [showcb, setshowcb] = useState(true);

  return (
    <>
      {isloading && <Loader />}
      <Router>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/products" element={<Products />} />
          <Route path="/partners" element={<Partners />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/hire-us" element={<HireUs />} />
          <Route path="/impact" element={<Impact />} />
          <Route path="/why-us" element={<Whyus />} />
          <Route path="/:slug" element={<Services />} />
        </Routes>
      </Router>
      <ToastContainer />
      <FaWhatsapp onClick={()=>{

        window.open("https://wa.link/q9125g")
      }} size={30} className="wa-container animate-bounce  "  />
      {showcb ? (
        <Chatbotbtn
          click={() => {
            setshowcb(false);
          }}
        />
      ) : (
        <Chatbotview
          cancel={() => {
            setshowcb(true);
          }}
        />
      )}
    </>
  );
};

export default App;
