import React, { useEffect } from "react";
import { useState } from "react";
import { services } from "../../constants/data";
import './Styles/Style.css'
import HeroServiceContainer from "./HeroServiceContainer";

const ServicesContainer = ({ slug }) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const temp = services.filter((item) => item.slug === slug);
    setItems(temp);
  }, [slug]);
  return (
    <>
      {   items.length > 0 && (
        <div>
          {
         !( (slug==='consultancy') ||   (slug==='it-infrastucture') || (slug==='cloud-solutions') )? 
          (items.map((item, index) => (
            // grid grid-cols-1 md:grid-cols-2 justify-start items-start
            <div
              key={index}
              className={`res-cont mt-10 flex flex-col md:flex-row items-center justify-between  ${
                index !== 0 ? "md:flex-row-reverse" : ""
              }`}
            >
              {/* Image */}
              <div className="md:flex md:justify-start justify-center md:w-[50%]">
                <img
                  src={item?.imgUrl}
                  alt="Services"
                  className="md:max-w-[500px]"
                />
              </div>
              {/* Description */}
              <div className="my-2 py-5 pr-10 md:w-[55%]">
                <h1 style={{}} className="heading">
                  {item?.header}
                </h1>
                <p className=" text-customBlue  text-left mt-8">
                  {item?.content1}
                </p>
                {item?.content2 && (
                  <p className="  text-customBlue  text-left mt-8">
                    {item?.content2}
                  </p>
                )}
              </div>
            </div>
          )) ) : <HeroServiceContainer slug ={slug}/>   }
   
</div>
      )   }
    </>
  );
};

export default ServicesContainer;
