
import { teamData } from "../../constants/data"



export default function (){



    return(<>
        
        
        <div className=" flex justify-center flex-column items-center  p-4 bg-[#F4F6FF]">
     <h1 className=" text-customBlue text-[26px] my-4">Meet The Team</h1>


     <div className="  grid md:grid-cols-2 gap-4">
{teamData.map((item)=>{



return(<div className=" flex justify-center items-center flex-column m-4">

<img src={item.img} className="rounded-full p-2 bg-white" width='250px' />
<h2>{item.name}</h2>
<p className="font-medium text-[#3a3869]">{item.position}</p>


</div>)
})}

</div>

    
    </div >
    <div className="p-4">



    </div>
    </>)
}
