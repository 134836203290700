export default function (props){


    var itemclass=`class${props.keyy}`
var onmouseover=()=>{
    document.getElementById(itemclass).style.backgroundColor = 'lightblue'
}



    return (<div onMouseOver={onmouseover} id={itemclass} className="m-2 p-2  bg-white flex flex-column items-start rounded">

<img src={props.item.img} />
<h3 id='wwdtitle' className=" text-customBlue text-[18px] m-2">{props.item.title}</h3>
<p id='wwddesc' className=" m-2 font-light text-customBlue text-left mt-">{props.item.desc}</p>
    
    </div>)
}