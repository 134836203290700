import Spinner from 'react-bootstrap/Spinner';



export default function(){


    return(<><div className='fixed flex justify-center items-center z-[22200] bg-[#00000096] w-[100vw] h-[100vh]'>
        
        <Spinner variant="primary"  animation="border" />
        </div></>)
}