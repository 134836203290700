import React from "react";
import { Link } from "react-router-dom";
import './Styles/Header.css'

function DropDownListItem({ item, index, length }) {
  return (
    <Link
    to={item.path}
    smooth={true}
    offset={-50}
    duration={500}
    // className="font-semibold"
  >
    <li
      key={item.id}
      className={'text-customBlue lil  rounded-lg  hover:text-primary font-[16px] text-[16px] hover:bg-[#efefef99] hover:font-medium p-2'}
    >
    
        {item.title}
    
    </li>
    </Link>
  );
}

export default DropDownListItem;
