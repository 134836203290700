
import './Styles/style.css'
import Form from 'react-bootstrap/Form';
import HireUsButton from './HireUsButton'
import emailjs from '@emailjs/browser'
import { IoIosSend } from "react-icons/io";
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {ErrorModal} from '../../component/Modal'
import Loader from '../../component/Loader'

var interested_serices=[]
export default function HireUsForm() {
var[ upload_visible, setuploadvisible]=useState(false)

    var project_details=useSelector(state=>state.details)
    var dispacher =useDispatch()

    emailjs.init({
        publicKey: 'Dj6VhRIdHNx3OoTdd',
        // Do not allow headless browsers
        blockHeadless: true,
        blockList: {
          // Block the suspended emails
          list: ['foo@emailjs.com', 'bar@emailjs.com'],
          // The variable contains the email address
          watchVariable: 'userEmail',
        },
        limitRate: {
          // Set the limit rate for the application
          id: 'app',
          // Allow 1 request per 10s
          throttle: 10000,
        },
      });


var [have_doc,sethavedoc]=useState(false)
var [showmodal, setshowmodal]=useState(false)

var [modalmsg, set_modalmsg]=useState('')
var [modaltitile, set_modaltitle]=useState('')

var projectypeclick =(e)=>{

   dispacher({type:'project_type', payload:e.target.value})
}

var add_services=(e)=>{
if (!(interested_serices.includes(e.target.value))){

interested_serices.push(e.target.value)
dispacher({type:'services', payload:interested_serices})

console.log(interested_serices)

}
}

    var submitform = () => {
        dispacher({type:'tg-loading',payload:true})

        console.log(project_details)
 var {name, email, phone,project_type,project_desc} =project_details

        if (name === '' || email === '' || phone === '' || project_desc === '' || project_type === '') {

            set_modalmsg('Fill in all required fields')
            set_modaltitle('Incomplete submission')
setshowmodal(true)
dispacher({type:'tg-loading', payload:false})


        }

        else{


            
            emailjs.send('service_i3rqfj9', 'template_r9xrpma', project_details).then(
                (response) => {
                    set_modalmsg('Your request has been sent, we would get back to you as soon as possible, Thank you.')
                    set_modaltitle('Success')
        setshowmodal(true)
        dispacher({type:'tg-loading', payload:false})

        
                },
                (error) => {
                    set_modalmsg('Could not send form')
            set_modaltitle('Failed')
setshowmodal(true)
dispacher({type:'tg-loading', payload:false})


                },
            );
        }






    }
    const services = [
        { title: "IT Services", path: "/it-support" },
        { title: "Cyber Security", path: "/cyber-security" },
        { title: "Home Automation", path: "/smart-home" },
        { title: "Software Development", path: "/software" },
        { title: "Consultancy", path: "/consultancy" },
        { title: "Digital Transformation Services", path: "/digital-transformation" },
        { title: "Cloud and servers", path: "/digital-transformation" },

        { title: "Supply and ICT Hardware Installation ", path: "/supply-and-installation" },
        { title: "IT Infrastructure Management", path: "/it-infrastucture" },
    ];
    return (<div className=" rounded-[20px] border border-indigo-600 m-4  md:m-[90px]">

        <div className='header   flex  align-center justify-center text-white'> <p className='bold text-lg text-[31px]'>Let's embark on a journey of digital transformation together</p>
        </div>

        <div className='form-name p-4 md:px-[70px] md:py-[50px]  flex justify-center flex-col items-center '>

            <h2 className='h2'>Project Type</h2>
            <div className='flex md:space-x-8 md:p-4' >


                <Form>
                    <Form.Check
                        className='m-2 md:text-[20px] font-thin text-[#293586]'
                        type={'radio'}
                        id={`default`}
                        name="radioGroup"
                        label={`New Project `}
                        onClick={(e)=>{projectypeclick(e)}}
                        value='New project'
                    />

                    <Form.Check 
                    className='m-2 font-thin md:text-[20px] text-[#293586]' 
                        type={'radio'}
                        id={`default`}
                        onClick={(e)=>{projectypeclick(e)}}
                        name="radioGroup"
                         label={`Existing Project`}
                        value='Existing project'
                    />
                </Form>

            </div>

            <h2 className='h2'>What service are you interested in?</h2>
            <div className='flex  md:p-4   grid grid-cols-1 md:grid-cols-3 md:gap-4 bg-white py-2 px-4'>

                {services.map((data) => (
                    <Form.Check className='font-thin m-2 text-[20px] text-[#293586] '// prettier-ignore
                        type={'checkbox'}
                        id={`default`}
                        onClick={(e)=>{add_services(e)}}
                        label={data.title}
                        value={data.title}


                    />
                ))}

            </div>


            <h2 className='h2'>Do you have a functional document?</h2>
            <div className='grid grid-cols-2 flex md:space-x-8 md:p-4' >

                <Form.Check className='m-2 font-thin text-[20px] text-[#293586]' // prettier-ignore
                    type={'radio'}
                    id={`default`}
                    name='doc'
                    onClick={()=>{

setuploadvisible(true)

                    }}
                    label={`Yes `}

                />
                <Form.Check className='m-2 font-thin text-[20px] text-[#293586]' // prettier-ignore
                    type={'radio'}
                    id={`default`}
                    onClick={()=>{

                        setuploadvisible(false)
                        
                                            }}
                    name='doc'
                    label={`No`}
                />
               {  upload_visible && (<><input className='p-3 m-3' type="file" id="documentUpload" name="documentUpload" accept=".pdf, .doc, .docx"/></>)}
            </div>


            <form className='deatil-form'>

                <div className='flex justify-center md:p-4 grid grid-cols-1 md:grid-cols-2 gap-4'>
                    <div >
                        <label >Full Name</label>
                        <input onChange= { (e) =>dispacher({type:'name', payload:e.target.value})} className=' mt-2 w-[100%] p-3' type='text' placeholder='Enter Full Name' />
                    </div>
                    <div >
                        <label >Email</label>
                        <input onChange= { (e) =>dispacher({type:'email', payload:e.target.value})} className=' mt-2 w-[100%] p-3' type='text' placeholder='Enter Email Address' />
                    </div>

                    <div >
                        <label >Phone No:</label>
                        <input onChange= { (e) =>dispacher({type:'phone', payload:e.target.value})}className=' mt-2 w-[100%] p-3' type='text' placeholder='Enter Phone' />
                    </div>
                    <div>
                        <label >Country</label>
                        <input onChange= { (e) =>dispacher({type:'country', payload:e.target.value})} className=' mt-2 w-[100%] p-3' type='text' value='Nigeria' placeholder='Enter Country' />
                    </div>



                </div>
                <div className='  md:p-4'>
                    <label>Project Description</label>

                    <input onChange= { (e) =>dispacher({type:'project_desc', payload:e.target.value})}className='mt-2 w-[100%] px-3 py-5 ' type='text' placeholder='Project Description' />


                </div>

                <div className='flex justify-center p-4 grid grid-cols-1 md:grid-cols-2 gap-4'>

                    <div >
                        <label >Remarks (Optional) </label>
                        <input onChange= { (e) =>dispacher({type:'remark', payload:e.target.value})} className=' mt-2 w-[100%] p-3' type='text' placeholder='Remark' />
                    </div>
                    <div >
                        <label >How did you hear about us? (Optional)</label>
                        <input onChange= { (e) =>dispacher({type:'how_youfoundus', payload:e.target.value})} className=' mt-2 w-[100%] p-3' type='text' placeholder='How did you find us' />
                    </div>
                </div>
                <div className='p-3 flex justify-center w-100%'>



                </div>
            </form>
            <HireUsButton 
            click={submitform} 
            text={(<>Send<IoIosSend className='inline ' /></>)
            } />
           {showmodal && (<ErrorModal handleClose={setshowmodal} msg={modalmsg} title= {modaltitile}  />)}
        
        </div>
       
    </div>)
}