import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './output.css';
import App from './App';
import { createStore } from 'redux'
import { configureStore } from '@reduxjs/toolkit';



import { Provider } from 'react-redux';
import {GIT_reducer} from './States/Reducers/GetInTouchReducer'
 
const root = ReactDOM.createRoot(document.getElementById('root'));
var renderApp =()=>{


    
    root.render(

        <Provider store={store}>
            <App  />
        
            </Provider>
        );
         

}

const store = configureStore ({
    reducer: GIT_reducer,
  });
store.subscribe(()=>{

    console.log(store.getState(),'subscribed')
    renderApp()
})

 renderApp()
