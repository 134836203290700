
import { Header, Footer } from "../component/Layout"
import Impact from '../component/Impact/Impact'
export default function () {


    return (<div>
        <Header />
        <Impact />
        <Footer />
    </div>)
}