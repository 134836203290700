import React from "react";
import { Footer, Header } from "../component/Layout";
import { Portfolio } from "../component/Home";

const Products = () => {
  return (
    <div>
      <Header />
      <Portfolio />
      <Footer />
    </div>
  );
};

export default Products;
