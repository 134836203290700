import HireUsForm from "../component/HireUs/HireUsForm";
import { Footer, Header } from "../component/Layout";

export default function HireUs(){

 return(<div>
    <Header/>
    <HireUsForm/>
    <Footer/>
    </div>)
}
