import React from "react";
import { servicesData } from "../../constants/data";
import ServiceItem from "./ServiceItem";

const Services = () => {
  return (
    <div>
      <div className="services res-cont w-full">
        <h1 className="text-center text-white text-xl md:text-4xl font-bold px-0 md:px-20 pt-4">
          We deliver exceptionally efficient and secure software applications
          with a seamless user experience
        </h1>
        <div className="w-full block md:flex justify-between items-center my-14">
          {servicesData.map((item, index) => (
            <ServiceItem  item={item} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
