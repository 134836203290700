import { oo1, oo2 } from "../../constants/imports";

import './Styles/styles.css'


export default function(){



    return (
    <div className="p-10  bg-[url('./assets/miniherobg.png')]  flex justify-center items-center flex-col   bg-cover ">
    
 
    <div className= "">
   
  
   <div className=" px-8   w-full my-6 bg-white rounded-xl    flex flex-col md:flex-row justify-center items-center   "> 
   <div className=" flex flex-col">

<h1 className="heading">Empowering People Through Technology</h1>

<p className="font-light text-customBlue  text-left mt- w-[70%] ">
RemotewebNET Dynamics Ltd is where technology and human touch converge. Since our inception in 2012, we have been devoted to transforming lives through cutting-edge IT solutions. Our mission goes beyond providing services; it's about empowering people and businesses to flourish in the digital era.</p></div>
<img className=' w-[370px] z-10' src={oo1} /> 
    </div> 
  
 
   <div className=" px-8 w-full my-6  bg-white   rounded-xl   flex flex-col md:flex-row justify-center items-center    "> 
   <div className=" flex flex-col">
<h1 className="heading">A Journey of Empowerment</h1>
<p className="font-light text-customBlue text-left mt- w-[70%] ">
At the core of our mission is the belief that technology should serve people, simplifying their lives and enhancing their experiences. From the start, we set out to bridge the gap between complex technology and real-world needs.</p></div>
<img className='  w-[370px] z-20' src={oo2} /> 
    
    </div> 
 
 
    </div>
     
        </div>
        
    )
}