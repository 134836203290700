
import { Header, Footer } from "../component/Layout"
 import Blog from "../component/Blog/Blog"
import { Partners } from "../component/Partners/Partners"
export default function () {


    return (<div>
        <Header />
        <Partners />
        <Footer />
    </div>)
}

