import React, { useState } from "react";
import { RiArrowDropDownLine } from "react-icons/ri";
const MoreServiceItem = ({ item }) => {
  const [onShow, setOnShow] = useState(false);

  return (
    <div onClick={()=>setOnShow(!onShow)} className="bg-white w-[300px] md:mx-5 my-4 image rounded-b-lg">
      <img onClick={()=>setOnShow(!onShow)} className="rounded-[8px] w-[320px] h-[200px]" src={item.img} alt="services" />
      <div className="p-3">
        <div className="flex justify-between items-center">
          <h1 onClick={()=>setOnShow(true)} className="font-semibold text-sm text-[#353535]">{item.title}</h1>
          <button type="button" className="" onClick={() => setOnShow(!onShow)}>
            {onShow ? (
              <RiArrowDropDownLine size ={40} className="text-primary" />
            ) : (
              <RiArrowDropDownLine size ={40} className="text-primary" />
            )}
          </button>
        </div>
        {onShow && (
          <p className="text-xs text-customBlue py-4 md:h-[160px]">
            {item.desc}
          </p>
        )}
      </div>
    </div>
  );
};

export default MoreServiceItem;
