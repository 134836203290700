import React from "react";
import { moreData } from "../../constants/data";
import MoreServiceItem from "./MoreServiceItem";

const MoreServices = () => {
  return (
    // md:-mt-12
    <div className="res-cont bg-faintBlue">
      {/* Content */}
      <div className="">
        <h1 className="small-heading">Our Services</h1>
        <h1 className="heading md:w-[70%]">
          We specialize in introducing state-of-the-art technological solutions
          aimed at boosting efficiency and productivity.
        </h1>
        <div className="flex flex-wrap justify-center items-start py-8">
          {moreData.map((item, index) => (
            <MoreServiceItem item={item} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MoreServices;
