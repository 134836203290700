import React, { useEffect, useState } from "react";
import Header from "../Layout/Header";
import { CloudSoluBG } from "../../constants/imports";
import { Link } from "react-router-dom";
import {services} from '../../constants/data'
import { tech } from "../../constants/imports";

const Hero = (props) => {
 
 var[data,setdata]= useState({})
 

 useEffect(()=>{

  services.map((item)=>{
    
     if (item.slug ===  props.slug){
      setdata(item)

 
     }
       
     
   

 })

},[props]
)
 


 
  return (
    <div id='hero-container' className={` ${data.slug} bg-cover bg-center  w-full h-[50vh]  md:h-[70vh]`}>
       <div className="h-full w-full flex justify-center items-center">
        <div className="flex flex-col justify-center  items-center w-full md:w-[70%]">
          <h1 className=" inline text-white text-2xl md:text-5xl font-bold text-center md:px-6">
            {data.header}
                </h1>
          <p className="md:text-lg text-white py-6 text-center md:px-28 px-8">
       {   data.content1}
          </p>
          {/* <Link to={"/hire-us"} className="primary-btn">
            Hire US
          </Link> */}
        </div>
      </div>
    </div>
  );
};

export default Hero;
