import React from "react";
import { AiOutlineClockCircle, AiOutlineMail } from "react-icons/ai";
import { FiPhoneCall } from "react-icons/fi";
import { Ri24HoursLine } from "react-icons/ri";
const ContactUs = () => {
  const Infoemail = "info@rwdynamics.com";
  const InfomailtoLink = `mailto:${Infoemail}`;
  return (
    <div className="res-cont services text-white">
      <h1 className="text-2xl font-semibold">Need a custom solution?</h1>
      <p className="text-white font-light">
      We acknowledge that some
situations require tailored solutions. <br />
        Our commitment is to collaborate with you in crafting customized
        solution that precisely aligns with your unique requirements.
      </p>
      <div
        style={{ backgroundColor: "rgba(89, 100, 176, 0.2)" }}
        className="md:flex block items-start justify-between py-8 px-16 mt-16"
      >
        {/* First Item */}
        <div className="py-6">
          {/* Icon */}
          <div>
            <AiOutlineClockCircle size={40} className="text-primary mb-4" />
          </div>
          <div>
            {/* Title */}
            <h1 className="font-bold mb-2">Working Hours</h1>
            {/* Desc */}
            <p className="font-light text-xs">Monday to Friday</p>
            <p className="font-light text-xs">8:00am-5:00pm</p>
          </div>
        </div>
        {/* Second Item */}
        <div className="py-6">
          {/* Icon */}
          <div>
            <FiPhoneCall size={40} className="text-primary mb-4" />
          </div>
          <div>
            {/* Title */}
            <h1 className="font-bold mb-2">Call Us</h1>
            {/* Desc */}
            <p className="font-light text-xs">+2348033917294</p>
          </div>
        </div>
        {/* Third Item */}
        <div className="py-6">
          {/* Icon */}
          <div>
            <Ri24HoursLine size={40} className="text-primary mb-4" />
          </div>
          <div>
            {/* Title */}
            <h1 className="font-bold mb-2">Online Support</h1>
            {/* Desc */}
            <p className="font-light text-xs">
              Support Service is always <br /> available for you 24/7
            </p>
          </div>
        </div>
        {/* Foutthr Item */}
        <div className="py-6">
          {/* Icon */}
          <div>
            <AiOutlineMail size={40} className="text-primary mb-4" />
          </div>
          <div>
            {/* Title */}
            <h1 className="font-bold mb-2">EMail Us</h1>
            {/* Desc */}
            <p className="font-light text-xs">
              <a href={InfomailtoLink} target="_blank">
                info@rwndynamics.com
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
